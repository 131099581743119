
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IOrganization,
        IOrganizationBusinessHours,
        IUpdateOrganizationBusinessHoursParams,
    } from '@/types';

    import {
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');

    @Component<OrganizationProfileOpeningHours>({
        components: {},
    })
    export default class OrganizationProfileOpeningHours extends Vue {
        public get businessHours(): IOrganizationBusinessHours[] {
            if (this.innerModel.business_hours.length === 0) {
                return [
                    {
                        day: 0,
                        openings: [],
                    },
                    {
                        day: 1,
                        openings: [],
                    },
                    {
                        day: 2,
                        openings: [],
                    },
                    {
                        day: 3,
                        openings: [],
                    },
                    {
                        day: 4,
                        openings: [],
                    },
                    {
                        day: 5,
                        openings: [],
                    },
                    {
                        day: 6,
                        openings: [],
                    },
                ];
            } else {
                return this.innerModel.business_hours;
            }
        }

        public openingStartRule = [
            (v: string) => !!v || 'L\'heure d\'ouverture est requise',
            (v: string) => /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(v) || 'Veuillez indiquer une heure valide',
        ];

        public openingEndRule = [
            (v: string) => !!v || 'L\'heure de fermeture est requise',
            (v: string) => /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(v) || 'Veuillez indiquer une heure valide',
            (v: string) => this.validateEndTime(),
        ];

        public customfieldRules = [
            (v: string | null) => ((v || '').length <= 30) || 'Le texte ne doit pas dépasser 30 caractères',
            (v: string | null) => !/\b\w{30,}\b/.test(v || '') || 'Les mots ne doivent pas dépasser 30 caractères',
        ];

        public innerModel!: IOrganization;
        public formValid: boolean = false;

        public openDays: {[key: number]: boolean} = {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        };

        public newOpening = {
            start: null,
            end: null,
            custom_text: null,
        };

        public dayEdited: number|null = null;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        public validateCustomField() {
            return this.customfieldRules.every(rule => rule(this.newOpening.custom_text) === true);
        }

        public toggleEditTimeSlot(dayIndex: number) {
            this.dayEdited = dayIndex;
            this.newOpening = {
                start: null,
                end: null,
                custom_text: null,
            };
        }

        public deleteTimeSlot(dayIndex: number, timeSlotIndex: number) {
            this.businessHours[dayIndex].openings.splice(timeSlotIndex, 1);
            this.dayEdited = null;
            this.submitUpdateOrganizationBusinessHours();
        }

        public addTimeSlot(dayIndex: number) {
            this.businessHours[dayIndex].openings.push(this.newOpening);
            this.newOpening = {
                start: null,
                end: null,
                custom_text: null,
            };
            this.dayEdited = null;
            this.submitUpdateOrganizationBusinessHours();
        }

        public submitUpdateOrganizationBusinessHours() {
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IUpdateOrganizationBusinessHoursParams = {
                organization_id: this.loggedOrganization.id,
                business_hours: this.businessHours,
            };

            organizationModule
                .update(params as IUpdateOrganizationBusinessHoursParams)
                .then((organization: IOrganization) => {
                    snackbarModule.displaySuccess(`
                        Les horaires de ${this.loggedOrganization.name} ont bien été modifiés !
                    `);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
            ;
        }

        public toggleSwitch(dayIndex: number, isActive: boolean) {
            if (isActive) {
                this.dayEdited = dayIndex;
                return;
            } else {
                this.dayEdited = null;
            }

            this.businessHours[dayIndex].openings = [];
            this.submitUpdateOrganizationBusinessHours();
        }

        private data() {
            return {
                innerModel: this.loggedOrganization ? JSON.parse(JSON.stringify(this.loggedOrganization)) : null,
            };
        }

        @Watch('loggedOrganization', {immediate: true})
        private onLoggedOrganizationChange(newVal: IOrganization) {
            if (newVal) {
                this.innerModel = JSON.parse(JSON.stringify(newVal));

                const openDays = this.innerModel
                    .business_hours
                    .filter((day: IOrganizationBusinessHours) => day.openings.length > 0)
                ;

                openDays.map((day: IOrganizationBusinessHours) => {
                    this.openDays[day.day] = true;
                });
            } else {
                (this.innerModel as IOrganization | null) = null;
                this.openDays = {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                };
            }
        }

        private validateEndTime() {
            if (!this.newOpening.start || !this.newOpening.end) {
                return true;
            }

            return (this.newOpening.start as IOrganization) < (this.newOpening.end as IOrganization) || 'Doit être supérieure à l\'heure d\'ouverture';
        }
    }

